import { lensIndex, pipe, set } from 'ramda'

// eslint-disable-next-line import/prefer-default-export
export function swap<T>(index1: number, index2: number, list: T[]) {
  if (
    index1 < 0 ||
    index2 < 0 ||
    index1 > list.length - 1 ||
    index2 > list.length - 1
  ) {
    return list // index out of bound
  }

  return pipe(
    set(lensIndex(index1), list[index2]),
    set(lensIndex(index2), list[index1])
  )(list)
}
