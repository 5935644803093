import React, { useEffect } from 'react'
import { FieldErrors, UseFormSetValue, useForm } from 'react-hook-form'
import { useRouter } from 'next/router'
import { ANALYTICS_CATEGORIES } from '@/constants/analytics'
import { yupResolver } from '@hookform/resolvers/yup'
import { PackagesFormStateType, MakeWatchObject } from '../types'
import { fields as FormFields } from './utils'
import { hasHotel, isHotelPlusCar } from './TripTypes'

type ChildrenFunctionArgs = {
  setValue: UseFormSetValue<PackagesFormStateType>
  errors: FieldErrors<PackagesFormStateType>
  // eslint-disable-next-line @typescript-eslint/ban-types
  register: Function
  watchState: MakeWatchObject<PackagesFormStateType>
}

type FormContainerProps = {
  children: (args: ChildrenFunctionArgs) => JSX.Element
  initialState: PackagesFormStateType
  onSubmit: (data: PackagesFormStateType) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  searchFormSchema: any
  fields: typeof FormFields
}

export default function FormContainer({
  children,
  initialState,
  onSubmit,
  fields,
  searchFormSchema
}: FormContainerProps) {
  const {
    register,
    unregister,
    setValue,
    clearErrors,
    formState,
    handleSubmit,
    watch
  } = useForm<PackagesFormStateType>({
    defaultValues: initialState,
    mode: 'onSubmit',
    resolver: yupResolver(searchFormSchema),
    reValidateMode: 'onChange'
  })

  const { errors } = formState

  useEffect(() => {
    fields.forEach(name => {
      register(name)
    })
    return () => {
      fields.forEach(name => {
        unregister(name)
      })
    }
  }, [fields, register, unregister])

  const {
    tripType: watchTripType,
    partialStay: watchPartialStay,
    startLocation: watchStartLocation,
    endLocation: watchEndLocation,
    startDate: watchStartDate,
    endDate: watchEndDate,
    roomCount: watchRoomCount,
    adultCount: watchAdultCount,
    childrenAges: watchChildrenAges,
    childrenCount: watchChildrenCount,
    hotelStartDate: watchHotelStartDate,
    hotelEndDate: watchHotelEndDate,
    pickupCarSomewhere: watchPickupCarSomewhere,
    pickupCarIncludeAirport: watchPickupCarIncludeAirport,
    rcLocation: watchRcLocation,
    rcPickupDate: watchRcPickupDate,
    rcDropoffDate: watchRcDropoffDate,
    rcPickupTime: watchRcPickupTime,
    rcDropoffTime: watchRcDropoffTime,
    gaCategory: watchGaCategory
  } = watch()

  useEffect(() => {
    clearErrors('startLocation')
  }, [clearErrors, watchStartLocation])
  useEffect(() => {
    clearErrors('endLocation')
  }, [clearErrors, watchEndLocation])
  useEffect(() => {
    clearErrors('rcLocation')
  }, [clearErrors, watchRcLocation])
  useEffect(() => {
    clearErrors('startDate')
  }, [clearErrors, watchStartDate])
  useEffect(() => {
    clearErrors('endDate')
  }, [clearErrors, watchEndDate])
  useEffect(() => {
    clearErrors('hotelStartDate')
  }, [clearErrors, watchHotelStartDate, watchPartialStay])
  useEffect(() => {
    clearErrors('hotelEndDate')
  }, [clearErrors, watchHotelEndDate, watchPartialStay])
  useEffect(() => {
    clearErrors('rcPickupDate')
  }, [clearErrors, watchRcPickupDate])
  useEffect(() => {
    clearErrors('rcDropoffDate')
  }, [clearErrors, watchRcDropoffDate])
  useEffect(() => {
    clearErrors('isInfantOnInternationalTravel')
  }, [clearErrors, watchStartLocation, watchEndLocation, watchChildrenAges])
  useEffect(() => {
    clearErrors('isChildrenAgeProvided')
  }, [clearErrors, watchChildrenCount, watchChildrenAges])
  useEffect(() => {
    if (watchPickupCarSomewhere) clearErrors('rcDropoffDate')
  }, [clearErrors, watchPickupCarSomewhere])
  const { pathname } = useRouter()
  const ANALYTICS_CATEGORY = ANALYTICS_CATEGORIES[pathname]

  useEffect(() => {
    setValue('gaCategory', ANALYTICS_CATEGORY)
  }, [setValue, ANALYTICS_CATEGORY])

  useEffect(() => {
    if (!hasHotel(watchTripType) || isHotelPlusCar(watchTripType)) {
      setValue('partialStay', false)
    }
  }, [setValue, watchTripType])

  return (
    <form aria-label="packages-search-form" onSubmit={handleSubmit(onSubmit)}>
      {children({
        setValue,
        errors,
        register,
        watchState: {
          watchTripType,
          watchAdultCount,
          watchRoomCount,
          watchChildrenAges,
          watchChildrenCount,
          watchHotelStartDate,
          watchHotelEndDate,
          watchStartDate,
          watchEndDate,
          watchPartialStay,
          watchStartLocation,
          watchEndLocation,
          watchPickupCarSomewhere,
          watchPickupCarIncludeAirport,
          watchRcLocation,
          watchRcPickupDate,
          watchRcDropoffDate,
          watchRcPickupTime,
          watchRcDropoffTime,
          watchGaCategory
        }
      })}
    </form>
  )
}
