import React from 'react'
import { UseFormSetValue } from 'react-hook-form'
import styled from 'styled-components'
import { Flex, Label, Radio } from 'pcln-design-system'
import { EnumLiteralsOf } from '@/types'
import { TripTypeInfos } from './TripTypes'
import { PackagesFormStateType } from '../types'

type TripTypeRadiosPropType = {
  setValue: UseFormSetValue<PackagesFormStateType>
  watchTripType: string
  tripTypes: Array<EnumLiteralsOf<typeof TripTypeInfos>>
}

const TripTypeLabel = styled(Label)`
  cursor: pointer;
  text-size-adjust: none;
`

function TripTypeRadios({
  setValue,
  watchTripType,
  tripTypes
}: TripTypeRadiosPropType) {
  return (
    <Flex
      alignItems={['left', null, 'center']}
      flexDirection={['column', null, 'row']}
      justifyContent="space-between"
    >
      <Flex flexDirection="row" flexWrap="wrap">
        {tripTypes.map(typeInfo => (
          <Flex
            mr={[0, null, 3]}
            alignItems="center"
            key={typeInfo.id}
            width={[1 / 2, null, 'auto']}
          >
            <TripTypeLabel fontSize={0} fontWeight={500} nowrap>
              <Radio
                id={typeInfo.id}
                type="radio"
                name="tripType"
                value={typeInfo.tripType}
                checked={watchTripType === typeInfo.tripType}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setValue('tripType', e.target.value, { shouldDirty: true })
                }}
              />{' '}
              {typeInfo.typeLabel}
            </TripTypeLabel>
          </Flex>
        ))}
      </Flex>
    </Flex>
  )
}

export default TripTypeRadios
